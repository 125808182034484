export const show = {
  sections: [
    {
      columns: [
        {
          size: 12,
          fields: [
            {
              type: 'TitleWithTagField',
              title: 'Show Load Incidents',
              variant: 'h4',
              source: 'status',
              props: {
                rules: [
                  {
                    value: 'Open',
                    color: 'orange',
                    range: 500
                  },
                  {
                    value: 'Closed',
                    color: 'green'
                  },
                  {
                    value: 'Pending response',
                    color: 'yellow'
                  },
                  {
                    value: 'Awaiting carrier',
                    color: 'yellow'
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      columns: [
        {
          size: 7,
          sections: [
            {
              columns: [
                {
                  size: 12,
                  sections: [
                    {
                      columns: [
                        {
                          size: 4,
                          fields: [
                            {
                              type: 'BoxWithIconField',
                              source: 'load_identifier',
                              title_link: 'load_tms_link',
                              subtitle: 'tender_amount_info',
                              icon: 'Inventory'
                            }
                          ]
                        },
                        {
                          size: 5,
                          fields: [
                            {
                              type: 'BoxWithIconField',
                              source: 'agent.name',
                              icon: 'Business',
                              subtitle: 'Agent'
                            }
                          ]
                        },
                        {
                          size: 3,
                          fields: [
                            {
                              type: 'BoxWithIconField',
                              source: 'dispatcher.name',
                              icon: 'Person',
                              subtitle: 'Dispatcher'
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  style: {
                    padding: '80px',
                    height: '180px',
                    marginLeft: '70px'
                  },
                  size: 12,
                  fields: [
                    {
                      type: 'TrackingRoadmapField',
                      source: 'load_payment_status',
                      props: {
                        size: 150,
                        icon: 'ContentPasteSearch',
                        iconSize: 'medium',
                        textTitle: 'load_identifier',
                        textDescription: 'amount_paid_info',
                        legend: 'carrier_payment.payment_status',
                        leftNodeTitle: 'origin_info',
                        rightNodeTitle: 'destination_info',
                        rules: [
                          {
                            value: 'Paid',
                            color: '#8FD14F'
                          },
                          {
                            value: 'unpaid',
                            color: '#FEF445'
                          }
                        ]
                      }
                    }
                  ]
                }
              ]
            },
            {
              columns: [
                {
                  size: 12,
                  fields: [
                    {
                      type: 'TitleWithTagField',
                      variant: 'h6',
                      source: 'load_tender_accepted_date_formatted',
                      title: 'Tender Date',
                      props: {
                        defaultColor: 'yellow'
                      }
                    }
                  ]
                }
              ]
            },
            {
              columns: [
                {
                  size: 6,
                  fields: [
                    {
                      type: 'TitleField',
                      source: 'Carrier',
                      variant: 'subtitle1'
                    }
                  ]
                },
                {
                  size: 6,
                  fields: [
                    {
                      type: 'TitleField',
                      source: 'Delivering Carrier',
                      variant: 'subtitle1'
                    }
                  ]
                },
                {
                  style: {
                    paddingRight: '4px',
                    marginTop: '10px',
                    marginBottom: '10px'
                  },
                  size: 6,
                  fields: [
                    {
                      type: 'CardWithIconField',
                      source: 'load_tender.carrier.name',
                      title_link: 'load_tender.carrier.tms_link',
                      icon: 'LocalShipping',
                      subtitle: 'carrier_info',
                      action: {
                        type: 'ScoreField',
                        source: "load_tender.carrier.score"
                      },
                      sections: [
                        {
                          style: {
                            marginLeft: '20px',
                            marginTop: '10px',
                            marginBottom: '10px'
                          },
                          columns: [
                            {
                              size: 4,
                              fields: [
                                {
                                  type: 'TextField',
                                  source: 'load_tender.carrier.contact.name',
                                  label: 'Tender Contact'
                                }
                              ]
                            },
                            {
                              size: 3,
                              fields: [
                                {
                                  type: 'TextField',
                                  source: 'load_tender.carrier.contact.phone',
                                  label: 'Phone'
                                }
                              ]
                            },
                            {
                              size: 5,
                              fields: [
                                {
                                  type: 'TextField',
                                  source: 'load_tender.carrier.contact.email',
                                  label: 'Email'
                                }
                              ]
                            },
                            {
                              size: 12,
                              style: {
                                marginTop: '20px'
                              },
                              fields: [
                                {
                                  type: 'TextField',
                                  source: 'load_tender.carrier.report_comments',
                                  label: 'DNU Comments'
                                }
                              ]
                            },
                            {
                              size: 12,
                              style: {
                                marginTop: '15px'
                              },
                              fields: [
                                {
                                  type: 'TextField',
                                  source: 'load_tender.carrier.review_comments',
                                  label: 'Review Comments'
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  style: {
                    paddingRight: '4px',
                    marginTop: '10px',
                    marginBottom: '10px'
                  },
                  size: 6,
                  fields: [
                    {
                      type: 'CardWithIconField',
                      source: 'delivering_carrier.name',
                      title_link: 'delivering_carrier.tms_link',
                      icon: 'LocalShipping',
                      subtitle: 'delivering_carrier_info',
                      action: {
                        type: 'ScoreField',
                        source: "delivering_carrier.score"
                      },
                      sections: [
                        {
                          style: {
                            marginLeft: '20px',
                            marginTop: '10px',
                            marginBottom: '10px'
                          },
                          columns: [
                            {
                              size: 4,
                              fields: [
                                {
                                  type: 'TextField',
                                  source: 'delivering_carrier.contact.name',
                                  label: 'Delivering Contact'
                                }
                              ]
                            },
                            {
                              size: 3,
                              fields: [
                                {
                                  type: 'TextField',
                                  source: 'delivering_carrier.contact.phone',
                                  label: 'Phone'
                                }
                              ]
                            },
                            {
                              size: 5,
                              fields: [
                                {
                                  type: 'TextField',
                                  source: 'delivering_carrier.contact.email',
                                  label: 'Email'
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              wrapper: 'CardWrapper',
              columns: [
                {
                  size: 6,
                  fields: [
                    {
                      type: 'TitleField',
                      source: 'Details'
                    },
                    {
                      type: 'TextField',
                      source: 'reason',
                      label: 'Reason'
                    },
                    {
                      type: 'TextField',
                      source: 'root_cause',
                      label: 'Root Cause'
                    },
                    {
                      type: 'BooleanField',
                      source: 'agent_negligence',
                      label: 'Agent Negligence'
                    },
                    {
                      type: 'BooleanField',
                      source: 'carrier_payment.quick_paid',
                      label: 'Load Quick Paid'
                    }
                  ]
                },
                {
                  size: 6,
                  style: {
                    paddingLeft: '20px'
                  },
                  props: {
                    style: {
                      marginTop: '8px',
                      marginLeft: '4px'
                    }
                  },
                  fields: [
                    [
                      {
                        type: 'TitleField',
                        source: 'Settlement',
                        style: {
                          marginBottom: '30px'
                        }
                      },
                      {
                        type: 'EditButton'
                      }
                    ],
                    {
                      type: 'TextField',
                      source: 'amount_paid_info',
                      label: 'Load Amount'
                    },
                    {
                      type: 'TextField',
                      source: 'settlement.payment_status',
                      label: 'Load Payment Status'
                    },
                    {
                      type: 'TextField',
                      source: 'settlement.agency',
                      label: 'Settlement Agency'
                    },
                    {
                      type: 'TextField',
                      source: 'settlement_amount_info',
                      label: 'Settlement Amount'
                    },
                    {
                      type: 'TextField',
                      source: 'settlement_payment_status',
                      label: 'Settlement Payment Status'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          style: {
            padding: '2px'
          },
          size: 5,
          fields: [
            {
              type: 'TimelineField',
              wrapper: 'WidgetWrapper',
              target: 'incident_id',
              icon: 'AccessTime',
              dateKey: 'date_created',
              formatDateTime: 'yyyy-MM-dd HH:mm:ss',
              sort: { field: 'date_created', order: 'DESC' },
              actions: [
                {
                  type: 'CreateButton',
                  props: {
                    resource: 'incident_activities',
                    state: {
                      record: { incident_id: 'id' }
                    },
                    label: 'New Activity'
                  }
                }
              ],
              props: {
                title: 'Recent Activities',
                enableInfo: false,
                actionsSide: 'space-between',
                reference: 'incident_activities'
              }
            }
          ]
        }
      ]
    }
  ]
}

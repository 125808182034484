import { testMode } from "../config/test/testConfig";
import { resources as base_config } from "../config/test/base-all-apis-config";

function fetchUserUIConfigFromFile() {
    return [{ resources: base_config }];
}

export async function fetchUserUIConfig(username) {

    const mapping = {
        "test": fetchUserUIConfigFromFile
    };

    const result = await mapping[testMode ? 'test' : 'prod'](username); // appConfig

    console.log("result", result);

    localStorage.setItem("appConfig", JSON.stringify({ resources: base_config }));
    return result;
}

import { edit } from './load_incidents.edit';
import { show } from './load_incidents.show';
import { list } from './load_incidents.list';

export const load_incidents = {
  icon: "FmdBad",
  name: "load_incidents",
  calculatedFields: [
    {
      name: "origin_info",
      function: "concatFields",
      options: {
        template: "{0}, {1}, {2}",
        keys: [
          "origin.city",
          "origin.state_province_code",
          "origin.country_code",
        ],
      },
    },
    {
      name: "destination_info",
      function: "concatFields",
      options: {
        template: "{0}, {1}, {2}",
        keys: [
          "destination.city",
          "destination.state_province_code",
          "destination.country_code",
        ],
      },
    },
    {
      name: "carrier_info",
      function: "concatFields",
      options: {
        template: "MC: {0} | DOT: {1} | NSC: {2}",
        keys: [
          "load_tender.carrier.mc",
          "load_tender.carrier.dot",
          "load_tender.carrier.nsc",
        ],
      },
    },
    {
      name: "delivering_carrier_info",
      function: "concatFields",
      options: {
        template: "MC {0} | DOT {1} | NSC {2}",
        keys: [
          "delivering_carrier.mc",
          "delivering_carrier.dot",
          "delivering_carrier.nsc",
        ],
      },
    },
    {
      name: "agent_info",
      function: "concatFields",
      options: {
        template: "{0}, {1}, {2}",
        keys: ["agent.email", "agent.phone"],
      },
    },
    {
      name: "amount_paid_info",
      function: "concatFields",
      options: {
        template: "{0} {1}",
        keys: ["carrier_payment.amount", "carrier_payment.currency"],
      },
    },
    {
      name: "settlement_amount_info",
      function: "concatFields",
      options: {
        template: "{0} {1}",
        keys: ["settlement.amount", "settlement.currency"],
      },
    },
    {
      name: "tender_amount_info",
      function: "concatFields",
      options: {
        template: "{0} {1}",
        symbol: "blank",
        keys: ["load_tender.rate.amount", "load_tender.rate.currency"],
      },
    },
    {
      name: "formatted",
      function: "formatDate",
      options: {
        template: "MM/dd/yyyy",
        keys: ["date_created", "load_tender.accepted_date"]
      }
    }
  ],
  show,
  edit,
  list
}

export const list = {
  rowClick: "show",
  searchMode: "memory",
  listActions: [
    {
      type: "ExportButton",
    },
  ],
  filterSidebar: {
    filterLists: [
      {
        icon: "AccessTime",
        label: "Status",
        items: [
          {
            label: "Open",
            value: { status: "Open" },
          },
          {
            label: "Closed",
            value: { status: "Closed" },
          },
          {
            label: "Pending response",
            value: { status: "Pending response" },
          },
          {
            label: "Awaiting carrier",
            value: { status: "Awaiting carrier" },
          },
        ],
      },
      {
        icon: "GppMaybe",
        label: "Reason",
        items: [
          {
            label: "Double Brokering",
            value: { reason: "Double Brokering" }
          },
          {
            label: "Held Load Hostage",
            value: { reason: "Held Load Hostage" }
          },
          {
            label: "Missing Load",
            value: { reason: "Missing Load" }
          },
          {
            label: "Poor Service",
            value: { reason: "Poor Service" }
          },
          {
            label: "Manually Reported Load",
            value: { reason: "Manually Reported Load" }
          },
          {
            label: "Risk Automation Process",
            value: { reason: "Risk Automation Process" }
          }
        ]
      }
    ],
  },
  fields: [
    {
      type: "TextField",
      source: "load_identifier",
    },
    {
      type: "TextField",
      source: "load_tender.carrier.mc",
      label: "Carrier MC",
    },
    {
      type: "TextField",
      source: "reason",
    },
    {
      type: "TextField",
      source: "amount_paid_info",
      label: "Amount Paid"
    },
    {
      type: "TextField",
      source: "tender_amount_info",
      label: "Tender Amount"
    },
    {
      type: "TagField",
      source: "status",
      props: {
        rules: [
          {
            value: "Open",
            color: "orange",
            range: 500,
          },
          {
            value: "Closed",
            color: "green",
          },
          {
            value: "Pending response",
            color: "yellow",
          },
          {
            value: "Awaiting carrier",
            color: "yellow",
          },
        ],
      },
    },
    {
      type: "TextField",
      source: "date_created",
    },
  ],
};

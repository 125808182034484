export const edit = {
  mutationOptions: {
    redirectTo: "show",
    mode: "pessimistic",
    message: {
      success: "Incident update successfully",
    },
  },
  spacing: 1,
  title: "Edit Incident",
  sections: [
    {
      columns: [
        {
          size: 12,
          fields: [
            {
              type: "TitleField",
              source: "Load Incident",
            },
            {
              type: "TrackingLinkField",
              source: "load_tms_link",
              title: "load_identifier",
              icon: "Inventory",
            },
          ],
        },
        {
          style: {
            marginTop: "30px",
          },
          size: 2,
          fields: [
            {
              type: "SelectInput",
              source: "status",
              choices: [
                { id: "Pending Response", description: "Pending Response" },
                { id: "Awaiting Carrier", description: "Awaiting Carrier" },
                { id: "Closed", description: "Closed" },
                { id: "Open", description: "Open" },
              ],
              optionText: "description",
              props: {
                variant: "outlined",
              },
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          size: 4,
          sections: [
            {
              columns: [
                {
                  wrapper: "CardWrapper",
                  title: "Details",
                  size: 8,
                  fields: [
                    {
                      type: "SelectInput",
                      source: "reason",
                      choices: [
                        {
                          id: "Double Brokering",
                          description: "Double Brokering",
                        },
                        {
                          id: "Held Load Hostage",
                          description: "Held Load Hostage",
                        },
                        { id: "Missing Load", description: "Missing Load" },
                        { id: "Poor Service", description: "Poor Service" },
                        {
                          id: "Manually Reported Load",
                          description: "Manually Reported Load",
                        },
                        {
                          id: "Risk Automation Process",
                          description: "Risk Automation Process",
                        },
                      ],
                      resettable: true,
                      optionText: "description",
                      props: {
                        variant: "outlined",
                      },
                    },
                    {
                      type: "TextInput",
                      source: "root_cause",
                      multiline: true,
                      props: {
                        variant: "outlined",
                      },
                    },
                    {
                      type: "NullableBooleanInput",
                      source: "agent_negligence",
                      props: {
                        variant: "outlined",
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          size: 4,
          sections: [
            {
              columns: [
                {
                  wrapper: "CardWrapper",
                  title: "Settlement",
                  props: {
                    style: {
                      marginRight: "10px",
                      marginLeft: "10px",
                    },
                  },
                  size: 12,
                  columns: [
                    {
                      size: 8,
                      sections: [
                        {
                          columns: [
                            {
                              size: 7,
                              style: {
                                paddingRight: "10px",
                              },
                              fields: [
                                {
                                  type: "TextInput",
                                  source: "carrier_payment.amount",
                                  props: {
                                    type: "number",
                                    variant: "outlined",
                                  },
                                },
                              ],
                            },
                            {
                              size: 5,
                              fields: [
                                {
                                  type: "SelectInput",
                                  source: "carrier_payment.currency",
                                  choices: [
                                    { id: "USD", description: "USD" },
                                    { id: "CAD", description: "CAD" },
                                  ],
                                  resettable: true,
                                  optionText: "description",
                                  props: {
                                    label: "Currency",
                                    variant: "outlined",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          columns: [
                            {
                              size: 12,
                              fields: [
                                {
                                  type: "TextInput",
                                  source: "settlement.agency",
                                  props: {
                                    variant: "outlined",
                                  },
                                },
                                {
                                  type: "SelectInput",
                                  source: "settlement.payment_status",
                                  choices: [
                                    { id: "Paid", description: "Paid" },
                                    { id: "Unpaid", description: "Unpaid" },
                                  ],
                                  resettable: true,
                                  optionText: "description",
                                  props: {
                                    variant: "outlined",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          columns: [
                            {
                              size: 7,
                              style: {
                                paddingRight: "10px",
                              },
                              fields: [
                                {
                                  type: "NumberInput",
                                  source: "settlement.amount",
                                  props: {
                                    variant: "outlined",
                                  },
                                },
                              ],
                            },
                            {
                              size: 5,
                              fields: [
                                {
                                  type: "SelectInput",
                                  source: "settlement.currency",
                                  choices: [
                                    { id: "USD", description: "USD" },
                                    { id: "CAD", description: "CAD" },
                                  ],
                                  resettable: true,
                                  optionText: "description",
                                  props: {
                                    label: "Currency",
                                    variant: "outlined",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          size: 4,
          sections: [
            {
              columns: [
                {
                  wrapper: "CardWrapper",
                  title: "Delivering Carrier",
                  size: 8,
                  columns: [
                    {
                      size: 12,
                      sections: [
                        {
                          columns: [
                            {
                              size: 12,
                              fields: [
                                {
                                  type: "TextInput",
                                  source: "delivering_carrier.name",
                                  multiline: true,
                                  props: {
                                    variant: "outlined",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      sections: [
                        {
                          columns: [
                            {
                              fields: [
                                {
                                  type: "TitleField",
                                  source: "Identifiers",
                                  variant: "h6",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          columns: [
                            {
                              size: 4,
                              style: {
                                paddingRight: "10px",
                              },
                              fields: [
                                {
                                  type: "TextInput",
                                  source: "delivering_carrier.mc",
                                  props: {
                                    variant: "outlined",
                                    label: "MC",
                                  },
                                },
                              ],
                            },
                            {
                              size: 4,
                              style: {
                                paddingLeft: "5px",
                                paddingRight: "5px",
                              },
                              fields: [
                                {
                                  type: "TextInput",
                                  source: "delivering_carrier.dot",
                                  props: {
                                    variant: "outlined",
                                    label: "DOT",
                                  },
                                },
                              ],
                            },
                            {
                              size: 4,
                              style: {
                                paddingLeft: "10px",
                              },
                              fields: [
                                {
                                  type: "TextInput",
                                  source: "delivering_carrier.nsc",
                                  props: {
                                    variant: "outlined",
                                    label: "NSC",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      size: 8,
                      style: {
                        paddingRight: "34px",
                      },
                      fields: [
                        {
                          type: "TitleField",
                          source: "Contact",
                          variant: "h6",
                        },
                        {
                          type: "TextInput",
                          source: "delivering_carrier.contact.name",
                          multiline: true,
                          props: {
                            variant: "outlined",
                            label: "Contact Name",
                          },
                        },
                        {
                          type: "TextInput",
                          source: "delivering_carrier.contact.email",
                          multiline: true,
                          props: {
                            variant: "outlined",
                            label: "Email Address",
                          },
                        },
                        {
                          type: "TextInput",
                          source: "delivering_carrier.contact.phone",
                          multiline: true,
                          props: {
                            variant: "outlined",
                            label: "Phone Number",
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

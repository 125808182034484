import {parseJwt} from "../utils";

export const TokenHandler = () => {
    // retrieves token and session_id from request header
    console.log('token handler called')
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const token = params.token
    const sessionId = params.session_id

    // retrieves sessionId from local_storage and compare with received session_id
    const storedSessionId = localStorage.getItem('sessionId')

    // TODO decide how to handle session_id not matching.
    if (sessionId !== storedSessionId) {
        console.error(`sessionId ${storedSessionId} from localStorage does not match session_id ${sessionId} from request`)
        return null
    }

    localStorage.setItem('token', token)
    const decoded_token = parseJwt(localStorage.getItem('token'))
    localStorage.setItem('username', decoded_token && decoded_token.username)
    window.location.replace('/dashboard')
}
import { useEffect, useState } from "react";
import { default as appConfig } from "./config/app-defaults-config.json";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route } from "react-router-dom";
import { CustomRoutes, Loading } from "react-admin";
import {
  AdminFactory,
  AppBuilderContextProvider,
} from "@spi3pl/components-builder";
import {components_mapping, mapping} from "./mappings";
import {Dashboard, LoginPageRedirect} from "./pages";
import {coreAuthProvider} from "./providers/coreAuthProvider";
import {fetchUserUIConfig, TokenHandler} from "./utils";


const App = () => {
  const [resourcesConfig, setResourcesConfig] = useState([]);
  const [mode] = useState("development");
  const [loading, setLoading] = useState(true);

  const queryClient = new QueryClient();

  // TODO get the username from the contexts
  const username = localStorage.getItem("username");

  useEffect(() => {
    fetchUserUIConfig(username).then((res) => {
      const userUIConfig = res ? res[0] : null;
      console.log("fetchUserUIConfig >> ", userUIConfig.resources);
      setResourcesConfig(userUIConfig.resources);
      setLoading(false); // Set loading to false after fetching the data
    });
  }, [username]);

  console.log("App.resourcesConfig", resourcesConfig);

  window.changeResourceConfig = (jsonConfig) => {
    if (mode !== "development") {
      console.log(`SPI Mode ==> ${mode}`);
      return;
    }
    if (isJSON(jsonConfig)) {
      console.warn("------------------------------------------------");
      console.warn(`------- I am working in ${mode} mode`);
      console.warn("------------------------------------------------");
      setResourcesConfig(jsonConfig);
      console.warn("------- New resource config installed ----------");
      console.info(resourcesConfig);
    } else {
      console.error("------------------------------------------------");
      console.error("------- Please, ensure that the JSON is valid");
      console.error("------------------------------------------------");
    }
  };

  if (loading) {
    return <Loading />; // Render a loading state while fetching the data
  }

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
      <AppBuilderContextProvider config={resourcesConfig} customMapping={components_mapping}>
        <AdminFactory
          customRoutes={
            <CustomRoutes>
              <Route exact path="/dashboard" element={Dashboard} />
              <Route path="/token" element={<TokenHandler/>}/>
            </CustomRoutes>
          }
          adminConfig={
            {
              loginPage: LoginPageRedirect,
              authProvider: coreAuthProvider,
              ready: Loading,
              dataProvider: mapping[appConfig.dataProvider]
            }
          }
        />
      </AppBuilderContextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};
export default App;

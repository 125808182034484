export const create = {
  mutationOptions: {
    redirectTo: 'show',
    resource: 'load_incidents',
    id: 'incident_id',
    message: {
      success: 'Activity created successfully'
    }
  },
  sections: [
    {
      columns: [
        {
          size: 4,
          fields: [
            {
              type: 'TextField',
              source: 'incident_id',
              label: 'Incident Reference'
            },
            {
              style: {
                marginTop: '20px',
                width: '50%'
              },
              type: 'SelectInput',
              source: 'type',
              choices: [
                { id: 'Update', description: 'Update' },
                { id: 'Resolution', description: 'Resolution' }
              ],
              resettable: true,
              optionText: 'description',
              props: {
                variant: 'outlined'
              }
            },
            {
              type: 'TextInput',
              source: 'notes',
              multiline: true,
              resettable: true,
              validations: [
                {
                  name: 'required',
                  message: 'The notes are required'
                }
              ],
              props: {
                variant: 'outlined'
              }
            }
          ]
        }
      ]
    }
  ]
}
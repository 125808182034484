import { testConfigDataProvider } from "../config";
import RaDataProvider from "@spi3pl/ra-data-providers";
//import { TestDataProvider } from "./testProvider";
import { AuthApiRestProvider } from "@spi3pl/ra-auth-providers";
import { CreateDataProvider } from "@spi3pl/http-client";

const dataProviderURL = new Map([
  ["applications", "raDataProvider"],
  ["environments", "raDataProvider"],
  ["load_incidents", "raDataProvider"],
  ["incident_activity_types", "raDataProvider"],
  ["incident_activities", "raDataProvider"],
]);

const strategies = {
  testAuthProvider: AuthApiRestProvider,
  raDataProvider: RaDataProvider,
  configApiDataProvider: testConfigDataProvider,
};

/**
 *
 * @param type
 * @param resource
 * @param params
 * @returns {Promise<*>}
 */
export default async (type, resource, params) => {
  console.info(
    `LOGGER::DataProvider => ${type} - ${resource} - ${JSON.stringify(params)}`
  );
  // Get the Types from the map.
  const value = dataProviderURL.get(resource);
  const url = testConfigDataProvider[value];
  // The Types object is wrapped in a function, so we can pass in the parameters to get the result.
  console.info(`LOGGER::DataProvider API_URL => ${url}`);
  console.info(`LOGGER::DataProvider VALUE => ${value}`);
  return await CreateDataProvider(type, resource, params).build(
    strategies[value](url)
  );
};
